import React from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router";
import DepartmentScreen from "../../screens/Department.screen";
import OperationScreen from "../../screens/Operation.screen";
import PreregPersonalScreen from "../../screens/PreregPersonal.screen";
import PreregTimeScreen from "../../screens/PreregTime.screen";
import PreregDateScreen from "../../screens/PreregDate.screen";
import {IDepartment} from "../../common/department/IDepartment";
import {IOperation} from "../../common/operation/IOperation";
import PreregLayout from "../../layouts/prereg/PreregLayout";
import RouteSlideAnimation from "../RouteSlideAnimation";


const PreregRoutes = () => {
    const {url} = useRouteMatch();
    const location = useLocation();
    const {pathname} = location;
    const history = useHistory();


    const onDepartment = (department: IDepartment) => {
        history.push(`${pathname}/operation/${department.key}`)
    }
    const onOperation = (operation: IOperation) => {
        history.push(`${pathname}/${operation.id}`)
    }

    const onDate = (date: string) => {
        history.push(`${pathname}/${date}`)
    }

    const onTime = (time: string) => {
        history.push(`${pathname}/${time}`)
    }
    return (
        <RouteSlideAnimation>
            <PreregLayout>
                <Switch>

                    {/* Personal form */}
                    <Route path={`${url}/operation/:did/:oid/:date/:time`}>
                        <PreregPersonalScreen/>
                    </Route>

                    {/* Time picker */}
                    <Route path={`${url}/operation/:did/:oid/:date`}>
                        <PreregTimeScreen onSelect={onTime}/>
                    </Route>

                    {/* Calendar, date picker */}
                    <Route path={`${url}/operation/:did/:oid`}>
                        <PreregDateScreen onSelect={onDate}/>
                    </Route>

                    {/* Operation list */}
                    <Route path={`${url}/operation/:did`}>
                        <OperationScreen onItemClick={onOperation}/>
                    </Route>

                    <Route path={`${url}`}>
                        <DepartmentScreen isPrereg onItemClick={onDepartment}/>
                    </Route>

                </Switch>
            </PreregLayout>
        </RouteSlideAnimation>
    )
}

export default PreregRoutes;