import * as React from 'react';
import './Logo.css';

type SizeType = 'normal' | 'small';
interface ILogoProps {
    size?: SizeType;
    className?: string;
}
const Logo: React.FC<ILogoProps> = ({ size = 'normal', className }) => {
    return <div className={`logo ${className}`} />;
};

export default Logo;
