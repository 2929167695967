import React, { useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import SplashScreen from '../components/SpashScreen/SplashScreen';
import Typography from '../components/core/Typography/Typography';
import useModal from '../hooks/useModal';
import Divider from '../components/core/Divider/Divider';
import { useHistory } from 'react-router';
import { useDepartments } from '../hooks/useDepartments';

const HomeScreen = () => {
    const { departments } = useDepartments();
    const { open, handleOpen, handleClose } = useModal();
    const history = useHistory();

    const navTo = (url: string, state: any = null) => {
        handleClose();
        history.push(url, state);
    };

    useEffect(() => {
        // TODO:: Сделать предзагрузку отделений и убрать таймаут
        if (!!departments) {
            handleOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);

    return (
        <>
            <SplashScreen />
            <BottomSheet open={open} snapPoints={() => 200}>
                <div className="px-1">
                    <div>
                        <Typography variant="h6" weight="bold">
                            Выберите тип обслуживания
                        </Typography>
                    </div>
                    <div className="py-2">
                        <div
                            onClick={() => navTo('/department')}
                            className="service-type service-now py-1">
                            <Typography variant="subtitle" weight="bold">
                                Сейчас &#8594;
                            </Typography>
                        </div>

                        <Divider />

                        <div
                            onClick={() => navTo('/prereg')}
                            className="service-type service-prereg py-1">
                            <Typography variant="subtitle" weight="bold">
                                Записаться на прием &#8594;
                            </Typography>
                        </div>

                        <Divider />

                        <div
                            onClick={() =>
                                navTo('/department', { isPreregCode: true })
                            }
                            className="service-type service-prereg-code py-1">
                            <Typography variant="subtitle" weight="bold">
                                Ввести код регистрациии &#8594;
                            </Typography>
                        </div>
                    </div>
                </div>
            </BottomSheet>
        </>
    );
};

export default HomeScreen;
