import React, { useEffect } from 'react';
type theme = 'light' | 'dark';

interface ProvidedValue {
    theme: theme;
    setTheme: (theme: theme) => void;
}

const Context = React.createContext<ProvidedValue>({
    theme: 'light',
    setTheme: () => {
        console.log('ThemeProvider is not rendered!');
    },
});

export interface Props {
    theme: theme;
    children?: React.ReactNode;
}

export const ThemeProvider = React.memo<Props>(props => {
    const [theme, setTheme] = React.useState<theme>(props.theme);

    const SetThemeCallback = React.useCallback(async (newTheme: theme) => {
        setTheme((currentTheme: theme) => {
            if (currentTheme === newTheme) {
                return currentTheme;
            }
            localStorage.setItem('user-prefer-theme', newTheme);
            return newTheme;
        });
        try {
        } catch (error) {}
    }, []);

    useEffect(() => {
        document.body.className = `${theme}-theme`;
        // const userTheme = localStorage.getItem('user-prefer-theme');
        // const targetTheme = userTheme || theme;
        // document.body.className = `${targetTheme}-theme`;
        // if (userTheme) {
        //     return setTheme(userTheme as theme);
        // }
        // if (
        //     window.matchMedia &&
        //     window.matchMedia('(prefers-color-scheme: dark)').matches
        // ) {
        //     setTheme('dark');
        //     document.body.className = `${theme}-theme`;
        //     window
        //         .matchMedia('(prefers-color-scheme: dark)')
        //         .addEventListener('change', e => {
        //             const newColorScheme = e.matches ? 'dark' : 'light';
        //             setTheme(newColorScheme);
        //         });
        // }
    }, [theme]);

    const MemoizedValue = React.useMemo(
        () => ({
            theme,
            setTheme: SetThemeCallback,
        }),
        [theme, SetThemeCallback],
    );

    return (
        <Context.Provider value={MemoizedValue}>
            {props.children}
        </Context.Provider>
    );
});

export const useTheme = () => React.useContext(Context);
