import { IResponseData } from '../../common/IResponseData';
import { ITicketStatus } from '../../common/ticket/ITicketStatus';
import { fetchData } from '../../utils/Http';
import { API } from '../API';

export interface ITicketService {
    status(): Promise<any>;
    register(operation_id: number): Promise<IResponseData<any>>
    prereg(access_code: number): Promise<IResponseData<any>>
    cancel(): Promise<IResponseData<any>>
}

export class TicketService implements ITicketService {
    public async status(): Promise<IResponseData<ITicketStatus>> {
        return await fetchData({ url: API.ticket.status });
    }
    public async register(operation_id: number): Promise<IResponseData<any>> {
        return await fetchData({
            url: API.ticket.register,
            params: { operation_id },
        });
    }
    public async prereg(access_code: number): Promise<IResponseData<any>> {
        return await fetchData({
            url: API.ticket.register,
            params: { access_code },
        });
    }
    public async cancel(): Promise<IResponseData<any>> {
        return await fetchData({
            url: API.ticket.cancel,
        });
    }
}
