import * as React from 'react';
import './Header.css';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../core/IconButton/IconButton';
import Logo from '../Logo/Logo';
import { Link } from 'react-router-dom';

export interface IHeaderProps {
    onMenu?(): void;
    onSearch?(): void;
}
const Header: React.FC<IHeaderProps> = ({ children, onMenu, onSearch }) => {
    return (
        <header className="header">
            <IconButton onClick={onMenu} size="lg" icon={faBars} />
            <Logo size="small" />
            <IconButton onClick={onSearch} size="lg" icon={faSearch} />
        </header>
    );
};

export default Header;
