import * as React from 'react';
import {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import {rootElement} from "../index";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const RouteSlideAnimation: React.FC = ({children}) => {
    const location = useLocation<any>();
    const {pathname, key} = location;
    const prevUrl = React.useRef('');
    const [direction, setDirection] = useState('in');

    useEffect(() => {
        rootElement?.scrollTo(0, 0);
        const prevState = prevUrl.current.split('/').length;
        const nextState = pathname.split('/').length;
        setDirection(prevState > nextState ? 'out' : 'in');
        prevUrl.current = pathname;
    }, [pathname]);

    return (
        <div className="slide-in-anime">
            <div className={direction}>
                <TransitionGroup>
                    <CSSTransition
                        key={key}
                        classNames="slide"
                        timeout={300}
                    >
                        {children}
                    </CSSTransition>
                </TransitionGroup>

            </div>
        </div>
    )
}

export default RouteSlideAnimation;