import useSWR from 'swr';
import {API} from '../api/API';
import {IResponseData} from '../common/IResponseData';
import {fetchData} from "../utils/Http";
import {IVisitTime} from "../common/preregistration/IVisitTime";

interface IUseOperationsProps {
    operationId: number;
    clientId: string;
    visitDay: string;
}

const fetcher = (url: string, key: string, operation_id: number, visit_day: string) => fetchData<IResponseData<IVisitTime[]>>( {url, params: {key, operation_id, visit_day}});

export function usePreregVisitTime({operationId, clientId, visitDay }: IUseOperationsProps) {
    const { data, error } = useSWR<IResponseData<IVisitTime[]>>(
        [API.preregistration.visit_time, clientId, operationId, visitDay],
        fetcher
    );

    return {
        times: data && data.data,
        isLoading: !error && !data,
        isError: error,
    };
}
