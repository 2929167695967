import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import IconButton from '../components/core/IconButton/IconButton';

const KioskErrorScreen = () => {
    const history = useHistory();
    const [message, setMessage] = useState<string>('');
    const [errorCode, setErrorCode] = useState<number>();

    useEffect(() => {
        if (history.location.state) {
            const { message: msg, error } = history.location.state as any;
            setMessage(msg);
            setErrorCode(error);
        }
    }, []);

    return (
        <div className={`kiosk-error error-code-${errorCode}`}>
            <div className="go-back" onClick={history.goBack}>
                <h6>
                    <IconButton icon={faArrowLeft} />
                    Назад
                </h6>
            </div>
            <div className="kiosk-error__container">
                <div className="kiosk-error__icon" />
                <div className="kiosk-error__message">
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                </div>
            </div>
        </div>
    );
};

export default KioskErrorScreen;
