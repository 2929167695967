import useSWR from 'swr';
import {API} from '../api/API';
import {IResponseData} from '../common/IResponseData';
import {IVisitDate} from '../common/preregistration/IVisitDate';
import {fetchData} from "../utils/Http";

interface IUseOperationsProps {
    operationId: number;
    clientId: string;
}

const fetcher = (url: string, key: string, operation_id: number) => fetchData<IResponseData<IVisitDate[]>>( {url, params: {key, operation_id, available: 1}});

export function usePreregVisitDate({operationId, clientId }: IUseOperationsProps) {
    const { data, error } = useSWR<IResponseData<IVisitDate[]>>(
        [API.preregistration.visit_date, clientId, operationId],
        fetcher
    );
    return {
        dates: data && data.data.map(({day_string}) => day_string),
        isLoading: !error && !data,
        isError: error,
    };
}
