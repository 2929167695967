import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { usePreregVisitTime } from '../hooks/usePreregVisitTime';
import TimePicker from '../components/TimePicker/TimePicker';
import Typography from '../components/core/Typography/Typography';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Button from '../components/core/Button/Button';
import IconButton from '../components/core/IconButton/IconButton';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Services } from '../api/services';
import useModal from '../hooks/useModal';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

const PreregTimeScreen: React.FC<any> = ({ onSelect }) => {
    const [preregCode, setPreregCode] = useState('');
    const { open, handleOpen } = useModal();
    const {
        open: errorOpen,
        handleOpen: errorHandleOpen,
        handleClose: errorHandleClose,
    } = useModal();

    const history = useHistory();
    const { pathname } = useLocation();
    const {
        oid: operationId,
        did: clientId,
        date: visitDay,
    } = useParams<any>();
    const { times = [] } = usePreregVisitTime({
        operationId,
        clientId,
        visitDay,
    });

    const onTime = async (time: string) => {
        try {
            const { success, data } = await Services.prereg.create({
                title: '',
                operation_id: parseInt(operationId, 10),
                description: '',
                key: clientId,
                visit_date: visitDay,
                visit_time: time,
                phone: '',
                email: '',
            });
            if (!success) {
                throw new Error('wrong');
            }
            handleOpen();
            setPreregCode(data.access_code);
        } catch (error) {
            // errror
            errorHandleOpen();
        }
    };

    const onCopy = () => {
        copy(preregCode);
        toast('Код регистрации скопирован!', { autoClose: 2000 });
    };

    return (
        <div>
            <div className="py-2">
                <Typography variant="h5" weight="bold">
                    Выберите время
                </Typography>
            </div>

            <TimePicker
                onItemClick={onTime}
                // onItemClick={(time: string) =>
                //     history.push(`${pathname}/${time}`)
                // }
                times={times}
            />

            <BottomSheet
                open={errorOpen}
                snapPoints={() => 250}
                onDismiss={errorHandleClose}>
                <div className="px-2">
                    <div className="text-center">
                        <Typography variant="h3" weight="bold">
                            Ошибка
                        </Typography>
                        <div className="py-1">
                            <Typography variant="h5" weight="semibold">
                                Что-то пошло не так
                            </Typography>
                        </div>
                        <div className="px-3 pt-1">
                            <Button size="lg" onClick={() => history.push('/')}>
                                <Typography variant="subtitle" weight="bold">
                                    На главную
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </BottomSheet>

            <BottomSheet open={open} snapPoints={() => 270}>
                <div className="px-2">
                    <div>
                        <Typography variant="subtitle" weight="semibold">
                            Ваш код регистрации
                        </Typography>
                    </div>
                    <div className="text-center flex justify-content-center align-items-center py-3">
                        <Typography variant="h4" weight="bold">
                            {preregCode}
                        </Typography>
                        <IconButton size="lgx" icon={faCopy} onClick={onCopy} />
                    </div>
                </div>
                <div className="px-3 pt-3">
                    <Button size="lg" onClick={() => history.push('/')}>
                        <Typography variant="subtitle" weight="bold">
                            На главную
                        </Typography>
                    </Button>
                </div>
            </BottomSheet>
        </div>
    );
};

export default PreregTimeScreen;
