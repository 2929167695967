import useSWR from "swr";
import {IResponseData} from "../common/IResponseData";
import {IDepartment} from "../common/department/IDepartment";
import {API} from "../api/API";
import {fetchData} from "../utils/Http";
interface IUseDepartmentsProps {
    isPrereg?: boolean
}
const initialProps: IUseDepartmentsProps = {isPrereg: false};
export function useDepartments({isPrereg} = initialProps) {

    const {data, error} = useSWR<IResponseData<IDepartment[]>>(isPrereg ? API.preregistration.departments : API.department.read, fetchData);
    return {
        departments: data && data.data,
        isLoading: !error && !data,
        isError: error,
    };
}
