import React from 'react';
import './Spinner.css'

interface ISpinnerProps {
    className?: string;
}
const Spinner: React.FC<ISpinnerProps> = ({className = ''}) => {
    return <div className={`loader ${className}`} />
}

export default Spinner