import React, {useState} from 'react';

import {BottomSheet} from 'react-spring-bottom-sheet';
import Typography from '../components/core/Typography/Typography';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import Button from "../components/core/Button/Button";
import {useTicket} from "../hooks/useTicket";
import {useHistory, useLocation} from "react-router";
import InputCode from "../components/InputCode/InputCode";
import IconButton from "../components/core/IconButton/IconButton";
import useModal from "../hooks/useModal";

interface IPreregCodeScreenProps {}

const PreregCodeScreen: React.FC<IPreregCodeScreenProps> = () => {
    const {prereg} = useTicket();
    const history = useHistory();
    const {pathname} = useLocation();
    const {open, handleOpen, handleClose} = useModal()
    const [code, setCode] = useState('');

    const onRegister = async () => {
        try {
            await prereg(parseInt(code, 10));
            history.push(pathname.split('/').slice(0, -2).join('/'));
        } catch(error) {
            handleOpen();
        }
    };

    return (
        <div>
            <div className="py-1">
                <IconButton icon={faArrowLeft} onClick={history.goBack} />
            </div>
            <div className="px-2 text-center">
                <Typography variant="h3" weight="bold">
                   Введите код
                </Typography>

                <div className="py">
                    <InputCode placeholder="7777" onChange={setCode} />
                </div>
                <div className="py-3"></div>
                <Button size="lg" onClick={onRegister}><Typography>Зарегистрировать</Typography></Button>
            </div>

            <BottomSheet open={open} onDismiss={handleClose}>
                <div className="px-2">
                    <Typography className="py" variant="h4">Ошибка</Typography>
                    <div className="py-1">
                        <Typography className="py" variant="subtitle">Что-то пошло не так</Typography>
                    </div>
                </div>
            </BottomSheet>
        </div>
    );
};

export default PreregCodeScreen;
