export function strReplace(
    str: string,
    pattern: string,
    replaceTo: string,
): string {
    try {
        return str.split(pattern).join(replaceTo);
    } catch (e) {
        return str;
    }
}
