import React from 'react';
import {Route, Switch} from 'react-router';
import KioskRoutes from "../kiosk/Kiosk.routes";
import PreregRoutes from "../prereg/Prereg.routes";
import HomeRoutes from "../home/HomeRoutes";

const MainRoutes = () => {
    return (
        <Switch>
            <Route  path={`/department/:id/kiosk`} component={KioskRoutes}/>
            <Route  path={`/prereg`} component={PreregRoutes}/>
            <Route  path="/" component={HomeRoutes}/>
        </Switch>
    )
};

export default MainRoutes;
