import * as React from 'react';
import {ChangeEvent, useEffect, useState} from "react";
import './TextField.css'
import Typography from "../Typography/Typography";

interface ITextFieldProps {
    name: string;
    label: string;
    value: string;
    error?: string | boolean;
    onChange(e: ChangeEvent<HTMLInputElement>): void;
    onBlur?(e: ChangeEvent<HTMLInputElement>): void;
}

const TextField: React.FC<ITextFieldProps> = ({name, label, value = '', onChange, onBlur: _onBlur, error}) => {
    const [activeLabel, setActiveLabel] = useState(!!value);
    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        setActiveLabel(!!value);
        _onBlur && _onBlur(e)
    }
    const onFocus = () => {
        setActiveLabel(true);
    }

    useEffect(() => {
        setActiveLabel(!!value);
    }, [value])

    return (
        <label className="textfield">
            <span className={`textfield__label ${activeLabel ? 'textfield__label_active' : ''}`}>{label}</span>
            <input className="textfield__input" type="text" name={name} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
            {error ? <Typography color="error" variant="small">{error}</Typography> : null}
        </label>
    )
}

export default TextField;