import React from 'react';
import {ITicketStatus} from '../common/ticket/ITicketStatus';
import Typography from '../components/core/Typography/Typography';

interface IThxScreenProps {
    data: ITicketStatus;
}

const ThxScreen: React.FC<IThxScreenProps> = ({
    data,
}) => {
    return (
        <div className="px-2">
            <div>
                <Typography variant="h6" weight="bold">
                    Обслуживание завершено
                </Typography>
            </div>
            <div className="py-1">
                <div className="py">
                    <Typography>Услуга: {data?.operation_title}</Typography>
                </div>
                {/* <div className="py">
                        <Typography>Время обслуживание: 15мин</Typography>
                    </div> */}
            </div>
        </div>
    );
};

export default ThxScreen;
