import React from 'react';
import './Typography.css';

interface ITyporgaphyProps {
    className?: string;
    variant?: variant,
    weight?: weight;
    color?: color;
}
type weight = 'bold' | 'extra-bold' | 'normal' | 'semibold';
type variant = 'small' | 'body' | 'subtitle' | 'large' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'promo';
type color = 'inherit' | 'primary' | 'secondary' | 'error';

const Typography: React.FC<ITyporgaphyProps> = ({className = '' ,color = 'inherit', variant = 'body', weight = 'normal', children}) => {
    const cls = [`typorgaphy`, `typorgaphy_${variant}`, `typorgaphy_${weight}`, `typorgaphy_color-${color}`, className]
    
    return <span className={cls.join(' ')}>{children}</span>
}

export default Typography;