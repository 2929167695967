import { IResponseData } from '../../common/IResponseData';
import { IOperation } from '../../common/operation/IOperation';
import { fetchData } from '../../utils/Http';
import { API } from '../API';
import {IDepartment} from "../../common/department/IDepartment";
import {IPreregSuccess} from "../../common/preregistration/IPreregSuccess";

export interface IPreregService {
    getOperations(s:any, id: any): Promise<IResponseData<IOperation[]>>;
    getDepartments(): Promise<IResponseData<IDepartment[]>>;
    cancel(): Promise<IResponseData<any>>;
}



interface ICreateProp {
    operation_id: number;
    key: string;
    title: string;
    visit_date: string;
    visit_time: string;
    phone: string;
    email: string;
    description: string;
}

export class PreregService implements IPreregService {
    public async getOperations(_: any, id: number): Promise<IResponseData<IOperation[]>> {
        const response = await fetchData<IResponseData<IOperation[]>>({ url: API.preregistration.operations });
        return response
    }

    public async getDepartments(): Promise<IResponseData<IDepartment[]>> {
        const response = await fetchData<IResponseData<IDepartment[]>>({ url: API.preregistration.departments });
        return response
    }

    public async cancel(): Promise<IResponseData<any>> {
        const response = await fetchData<IResponseData<any[]>>({ url: API.preregistration.cancel });
        return response
    }


    public async create(data: ICreateProp): Promise<IResponseData<IPreregSuccess>> {
        return await fetchData<IResponseData<IPreregSuccess>>({ url: API.preregistration.create, method: 'POST', data });
    }

}