import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './IconButton.css';

interface IIconButtonProps {
    size?: ButtonSize;
    variant?: ButtonVariant;
    className?: string;
    icon: IconProp;
    onClick?(): void;
}
type ButtonSize = 'lgx' | 'lg' | 'md' | 'sm' | 'xs';
type ButtonVariant = 'primary' | 'secondary' | 'active' | 'link';

const Button: React.FC<IIconButtonProps> = ({className = '', icon, size = 'md', variant = 'link', children, onClick}) => {
    const cls = [className, 'icon-button', `icon-button_${size}`, `icon-button_${variant}`];
    return (
        <button onClick={onClick} className={cls.join(' ')}>
            <FontAwesomeIcon  icon={icon}/>
        </button>
    )
}


export default Button