import React from 'react';
import Typography from '../components/core/Typography/Typography';
import { useTheme } from '../providers/ThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Divider from '../components/core/Divider/Divider';

interface IAppMenuScreenProps {
    onLinkClick?(): void;
}

const AppMenuScreen: React.FC<IAppMenuScreenProps> = ({ onLinkClick }) => {
    const { theme, setTheme } = useTheme();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { id: departmentId } = useParams<any>();

    const themeIcon = theme === 'light' ? faMoon : faSun;
    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    const handleOpen = () => {
        history.push(`${url}/prereg/code`);
        onLinkClick && onLinkClick();
    };

    return (
        <div className="app-menu-screen">
            {/* <div style={{ position: 'absolute', top: 20, right: 20 }}>
                <FontAwesomeIcon
                    color="var(--color-text)"
                    size="lg"
                    icon={themeIcon}
                    onClick={toggleTheme}
                />
            </div> */}
            <div className="px-2">
                <div>
                    <Typography variant="h4" weight="bold">
                        Меню
                    </Typography>
                </div>
                <div className="py-2">
                    <div
                        className="py-1"
                        onClick={() =>
                            history.push(`/prereg/operation/${departmentId}`)
                        }>
                        <Typography variant="subtitle" weight="bold">
                            Предрегистрация &#8594;
                        </Typography>
                    </div>
                    <Divider />
                    <div onClick={handleOpen} className="py-1">
                        <Typography variant="subtitle" weight="bold">
                            Ввод кода &#8594;
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AppMenuScreen;
