import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Router } from 'react-router';
import { SWRConfig } from 'swr';
import { ThemeProvider } from './providers/ThemeProvider';
import { fetchData } from './utils/Http';
import { history } from './history';
import { AppProvider } from './providers/AppProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const rootElement = document.getElementById('root');
ReactDOM.render(
    <SWRConfig
        value={{
            fetcher: url => fetchData({ url }),
        }}>
        <ThemeProvider theme="light">
            <AppProvider>
                <Router history={history}>
                    <App />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Router>
            </AppProvider>
        </ThemeProvider>
    </SWRConfig>,
    rootElement,
);
