import axios from "axios";
import conf from "../config";

axios.interceptors.request.use(async (config) => {
    config.baseURL = conf.baseUrl;
    config.timeout = 10000;
    // config.params = {...config.params};
    config.withCredentials = true;
    return config;
});

export default axios;