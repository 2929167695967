import React from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router";
import DepartmentScreen from "../../screens/Department.screen";
import {IDepartment} from "../../common/department/IDepartment";
import RouteSlideAnimation from "../RouteSlideAnimation";
import HomeScreen from "../../screens/Home.screen";


const HomeRoutes = () => {
    const {url} = useRouteMatch();
    const location = useLocation();
    const {state} = useLocation<any>();
    const history = useHistory();

    const onClick = (item: IDepartment) => {
        if(state?.isPreregCode) {
            history.push(`/department/${item.key}/kiosk/prereg/code`);
            return;
        }
        history.push(`/department/${item.key}/kiosk`)
    }

    return (
        <RouteSlideAnimation>
                <Switch location={location}>
                    <Route path={`${url}department`}>
                        <div className="px-2 py-2">
                            <DepartmentScreen onItemClick={onClick}/>
                        </div>
                    </Route>
                    <Route path={url} component={HomeScreen}/>
                </Switch>
        </RouteSlideAnimation>
    )
}

export default HomeRoutes;