import { IResponseData } from '../../common/IResponseData';
import { IOperation } from '../../common/operation/IOperation';
import { fetchData } from '../../utils/Http';
import { API } from '../API';

export interface IOperationService {
    getList(): Promise<any>;
}

export class OperationService implements IOperationService {
    public async getList(): Promise<IResponseData<IOperation[]>> {
        const response = await fetchData<IResponseData<IOperation[]>>({ url: API.operation.read });
        return response
    }

    public async getTop(): Promise<IResponseData<IOperation[]>> {
        const response = await fetchData<IResponseData<IOperation[]>>({ url: API.operation.readTop });
        return response
    }

}