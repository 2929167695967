import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { API } from '../../api/API';
import { EMenuType } from '../../common/menu/EMenuType';
import { IMenu } from '../../common/menu/IMenu';
import { IMenuOperation } from '../../common/operation/IMenuOperation';
import { useApp } from '../../providers/AppProvider';
import { descriptionParser } from '../../utils/descriptionParser';
import IconButton from '../core/IconButton/IconButton';
import Nl2br from '../nl2br/Nl2br';
import './MenuList.css';

interface IMenuProps {
    dense?: boolean;
    parent?: IMenu;
    menus: IMenu[];
    onDir(val: any): void;
    onOperation(val: IMenuOperation): void;
}

const MenuListDefault: React.FC<IMenuProps> = ({
    menus,
    parent,
    onDir,
    onOperation,
}) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { appDepartment, appCls, setAppCls } = useApp();

    useEffect(() => {
        if (parent) {
            const parentDescriptionMeta = descriptionParser(parent.description);
            // Crutch, sub/sub folder reseted
            if (!appCls) {
                setAppCls(`${parentDescriptionMeta.tag}`);
            }
        } else {
            setAppCls('');
        }
    }, [parent]);

    const onMenuItemClick = (menuItem: IMenu, tag?: string) => {
        // TODO: tag actions
        if (tag && tag == 'prereg') {
            history.push(`/department/${appDepartment}/kiosk/prereg/code`);
            return;
        }

        !!menuItem.operation && menuItem.type === EMenuType.OPERATION
            ? onOperation(menuItem.operation)
            : onDir(menuItem);
    };

    return (
        <div className={`menu-list-container ${appCls}`}>
            {parent && (
                <div onClick={history.goBack}>
                    <h6>
                        <IconButton icon={faArrowLeft} />
                        {parent.title}
                    </h6>
                </div>
            )}
            <div className={`menu-list ${parent ? 'sub-dir' : 'root-dir'}`}>
                {menus.map((menu: IMenu) => {
                    const menuDescriptionMeta = descriptionParser(
                        menu.description,
                    );
                    return (
                        <div
                            key={`${menu.id}`}
                            onClick={() =>
                                onMenuItemClick(menu, menuDescriptionMeta.tag)
                            }
                            className={`menu-list__item ${menuDescriptionMeta.tag}`}>
                            <div className="menu-list__item__title">
                                <Nl2br str={menu.title} />
                            </div>
                            {menuDescriptionMeta.descriptionText && (
                                <div className="menu-list__item__description">
                                    <Nl2br
                                        str={
                                            menuDescriptionMeta.descriptionText
                                        }
                                    />
                                </div>
                            )}
                            {menu.icon_id > 0 && (
                                <div
                                    className="menu-list__item__icon"
                                    style={{
                                        backgroundImage: `url(${API.operation.getIcon}/?icon_id=${menu.icon_id})`,
                                    }}></div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default MenuListDefault;
