import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Divider from '../components/core/Divider/Divider';
import Typography from '../components/core/Typography/Typography';
import MenuListDefault from '../components/MenuList/MenuListDefault';
import TopOperations from '../components/TopOperations/TopOperation';
import { useMenuList } from '../hooks/useMenuList';
import useModal from '../hooks/useModal';
import { useTicket } from '../hooks/useTicket';
import { useTopOperations } from '../hooks/useTopOperations';
import { useApp } from '../providers/AppProvider';
import ErrorScreen from './Error.screen';

const KioskScreen = () => {
    const { appCls } = useApp();
    const { menus, parent } = useMenuList();
    const history = useHistory();
    const { error, register } = useTicket();
    // const { operations } = useTopOperations();
    const { url } = useRouteMatch();
    const {
        open: errorOpen,
        handleOpen: errorHandleOpen,
        handleClose: errorHandleClose,
    } = useModal();

    useEffect(() => {
        if (error) {
            history.push(`/department/mdu/kiosk/error`, error);
            // errorHandleOpen();
        }
    }, [error]);

    const onDir = (dir: any) => {
        history.push(`${url}/menu/${dir.id}`);
    };
    if (!menus) {
        return null;
    }

    return (
        <div>
            {/* {operations && !!operations.length && (
                <>
                    <div className="top-operations-header">
                        <Typography variant="h6" weight="bold">
                            Популярное
                        </Typography>
                    </div>
                    <TopOperations
                        onOperation={register}
                        operations={operations}
                    />
                    <Divider />
                </>
            )} */}
            <div className="menu-list-container">
                <h6>Выберите услугу</h6>
                <MenuListDefault
                    onOperation={register}
                    menus={menus}
                    parent={parent}
                    onDir={onDir}
                />
                <BottomSheet
                    className={`${appCls}`}
                    open={errorOpen}
                    onDismiss={errorHandleClose}
                    snapPoints={() => 300}>
                    {error && <ErrorScreen message={error.message} />}
                </BottomSheet>
            </div>
        </div>
    );
};

export default KioskScreen;
