import React, { useCallback } from 'react';

interface ProvidedValue {
    loading: boolean;
    setLoading: (theme: boolean) => void;
    appCls: string;
    setAppCls: (cls: string) => void;
    appDepartment: string;
    setAppDepartment: (dep: string) => void;
}

const Context = React.createContext<ProvidedValue>({
    loading: false,
    setLoading: (val: boolean) => {
        console.log('ThemeProvider is not rendered!');
    },
    appCls: '',
    setAppCls: (cls: string) => {
        console.log('ThemeProvider is not rendered!');
    },
    appDepartment: '',
    setAppDepartment: (dep: string) => {
        console.log('ThemeProvider is not rendered!');
    },
});

export interface Props {
    children?: React.ReactNode;
}

export const AppProvider = React.memo<Props>(props => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [appCls, setAppCls] = React.useState<string>('');
    const [appDepartment, setAppDepartment] = React.useState<string>('');

    const setAppClsHandler = useCallback((cls: string) => {
        setAppCls(cls);
    }, []);

    const setAppDepartmentHandler = useCallback((cls: string) => {
        setAppDepartment(cls);
    }, []);

    const MemoizedValue = React.useMemo(
        () => ({
            appCls,
            loading,
            setLoading,
            setAppCls: setAppClsHandler,
            appDepartment,
            setAppDepartment: setAppDepartmentHandler,
        }),
        [
            loading,
            setLoading,
            appCls,
            setAppClsHandler,
            appDepartment,
            setAppDepartmentHandler,
        ],
    );

    return (
        <Context.Provider value={MemoizedValue}>
            <div className={`app-provider-container ${appCls}`}>
                {props.children}
                {loading && <div className={`overlay overlay_app}`}></div>}
            </div>
        </Context.Provider>
    );
});

export const useApp = () => React.useContext(Context);
