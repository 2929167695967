import * as React from 'react';
import {useMemo, useState} from 'react';
import Typography from "../components/core/Typography/Typography";
import InputSearch from "../components/core/InputSearch/InputSearch";
import {debounce} from "../utils/debounce";
import FlatList from "../components/core/FlatList/FlatList";
import {IDepartment} from "../common/department/IDepartment";
import {useDepartments} from "../hooks/useDepartments";

interface IDepartmentScreenProps {
    isPrereg?: boolean;
    onItemClick(item: IDepartment): void
}
const DepartmentScreen:React.FC<IDepartmentScreenProps> = ({isPrereg, onItemClick}) => {
    const [search, setSearch] = useState('');
    const {departments, isLoading} = useDepartments({isPrereg});

    const onClick = (department: IDepartment) => {
        onItemClick(department);
    }

    const filteredDepartments = useMemo(() => !search ? departments : departments?.filter(department => department.title.toLowerCase().includes(search.toLowerCase())), [search, departments]);

    return (
        <div>
            <div className="pt-2">
                <Typography variant="h5" weight="bold">
                    Выберите отделение
                </Typography>
            </div>

            <div className="py">
                <InputSearch placeholder="Поиск" onChange={debounce(setSearch, 300)}/>
            </div>

            <div className="department-list">
                <FlatList
                    data={filteredDepartments || []}
                    renderItem={(item: IDepartment) => <div onClick={ () => onClick(item)} className="py-1" key={item.id}>{item.title}</div>}
                    loading={isLoading}
                    divided
                />
            </div>
        </div>
    );
}

export default DepartmentScreen