import React from 'react';
import { ETicketStatus } from '../common/ticket/ETicketStatus';
import { ITicketStatus } from '../common/ticket/ITicketStatus';
import Button from '../components/core/Button/Button';
import Typography from '../components/core/Typography/Typography';
import Nl2br from '../components/nl2br/Nl2br';

const statusText = {
    [ETicketStatus.Invalid]: 'Неизвестно',
    [ETicketStatus.Waiting]: 'Ожидание',
    [ETicketStatus.Holding]: 'Удержание',
    [ETicketStatus.Calling]: 'Вызывается',
    [ETicketStatus.Processing]: 'Обслуживание',
    [ETicketStatus.Reset]: 'Сброшен',
    [ETicketStatus.Dropped]: 'Закрыт',
    [ETicketStatus.Finished]: 'Завершено',
    [ETicketStatus.RedirectedToWorkplace]: 'Перенаправлен',
    [ETicketStatus.RedirectedToOperation]: 'Перенаправлен',
    [ETicketStatus.RedirectedToOperator]: 'Перенаправлен',
};
function parseStatus(status: ETicketStatus | null) {
    return statusText[status || ETicketStatus.Invalid] || 'Неизвестно';
}

function toMin(value: number | null) {
    if (!value) {
        return 0;
    }
    return Math.floor(value / 60).toFixed(0);
}
interface ITicketScreenProps {
    data: ITicketStatus;
    onCancel(): void;
}

const TicketScreen: React.FC<ITicketScreenProps> = ({ data, onCancel }) => {
    return (
        <div className="ticket-screen">
            {data?.status ? (
                <div className="px-2">
                    <div>
                        <Typography
                            variant="h6"
                            weight="bold"
                            className="ticket-header">
                            Талон
                        </Typography>
                    </div>
                    <div className="text-center">
                        <div className="py">
                            <Typography
                                variant="subtitle"
                                className="ticket-status">
                                {parseStatus(data?.status)}
                            </Typography>
                        </div>

                        {data?.workplace_window ? (
                            <div className="py">
                                <Typography
                                    variant="h6"
                                    weight="bold"
                                    className="ticket-window">
                                    Окно: {data?.workplace_window}
                                </Typography>
                            </div>
                        ) : null}

                        <Typography
                            variant="promo"
                            weight="bold"
                            className="ticket-number">
                            {data?.number}
                        </Typography>
                        <div className="py">
                            <Typography
                                variant="large"
                                className="ticket-operation">
                                <Nl2br
                                    str={`${data?.operation_title}`}
                                    replaceTo=" "
                                />
                            </Typography>
                        </div>
                        {data?.status === ETicketStatus.Waiting ? (
                            <>
                                <div className="py ticket-wait-count">
                                    <Typography>
                                        Талонов перед вами:
                                        <span>
                                            {data?.approximate_wait_count || 0}
                                        </span>
                                    </Typography>
                                </div>
                                <div className="ticket-wait-time">
                                    <Typography>
                                        Время ожидания:
                                        <span>
                                            {toMin(data?.approximate_wait_time)}{' '}
                                            мин.
                                        </span>
                                    </Typography>
                                </div>
                                <div className="ticket-cancel-button px-4 py-2">
                                    <Button size="lg" onClick={onCancel}>
                                        <Typography weight="bold">
                                            Отменить
                                        </Typography>
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default TicketScreen;
