/**
 * Menu description parser
 * meta info
 */
interface IDescriptionParser {
    descriptionText: string;
    tag: string;
}
export function descriptionParser(
    description: string = '',
    spliter: string = '#',
): IDescriptionParser {
    const result = {
        descriptionText: '',
        tag: '',
    };

    // split description text
    try {
        const descr: any = description.split(spliter);
        result.descriptionText = descr[0].trim();

        if (descr.length > 1) {
            result.tag = descr[1].trim();
        }
    } catch (e) {
        // console.log(e);
    }

    return result;
}
