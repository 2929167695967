import { IResponse } from '../../common/IResponse';
import { fetchData } from '../../utils/Http';
import { API } from '../API';

export interface IAppService {
    init(id: string, notify_token: string): Promise<any>;
}

export class AppService implements IAppService {
    public async init(id: string, notify_token: string): Promise<IResponse> {
        return await fetchData({ url: API.app.init, params: {id, notify_token} });
    }
    public async getFcmConfig(): Promise<any> {
        return await fetchData({ url: API.fcm.config});
    }
}
