import React from 'react';
import Header, { IHeaderProps } from '../components/Header/Header';
import './AppLayout.css';
interface IAppLayoutProps extends IHeaderProps{
    
}
const AppLayout: React.FC<IAppLayoutProps> = ({ children, onMenu, onSearch }) => {
    return (
        <div className="layout">
            <Header onMenu={onMenu} onSearch={onSearch} />
            <main className="content">
                {children}
            </main>
        </div>
    );
};
export default AppLayout;
