import { appMock } from './app.mock';
import { menuMock } from './menu.mock';
import { operationMock } from './operation.mock';
import { ticketMock } from './ticket.mock';
import {preregistrationMock} from "./preregistration.mock";

interface ISimulateApi {
    [key: string]: any;
}

export const simulateApi: ISimulateApi = {
    ...menuMock,
    ...appMock,
    ...operationMock,
    ...ticketMock,
    ...preregistrationMock
};
