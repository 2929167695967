import React from 'react';
import './PreregLayout.css';
import IconButton from "../../components/core/IconButton/IconButton";
import Typography from "../../components/core/Typography/Typography";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import {useHistory} from "react-router";

const PreregLayout: React.FC = ({ children}) => {
    const history = useHistory();
    return (
        <div className="layout-prereg">
            <header className="layout-prereg__header">
                <div className="layout-prereg__header-control">
                    <IconButton icon={faArrowLeft} onClick={history.goBack} />
                </div>
                <div className="layout-prereg__header-title">
                    <Typography variant="subtitle" weight="semibold">Запись</Typography>
                </div>
                <div className="layout-prereg__header-empty"></div>
            </header>
            <main className="layout-prereg__content">
                {children}
            </main>
        </div>
    );
};
export default PreregLayout;
