import React from 'react';
import Spinner from '../core/Spinner/Spinner';
import Logo from '../Logo/Logo';
import './SplashScreen.css'

const SplashScreen = () => {
    return <div className="splash">
        <div className="splash__logo-container">
            <Logo />
        </div>
        <Spinner />
    </div>
}

export default SplashScreen;