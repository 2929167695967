import React from 'react';
import Typography from '../components/core/Typography/Typography';

interface IErrorScreenProps {
    title?: string;
    message: string;
}

const ErrorScreen: React.FC<IErrorScreenProps> = ({ title, message }) => {
    return (
        <div className="px-2 error-container">
            <div>
                <Typography variant="h6" weight="bold">
                    {title ? title : 'Ошибка'}
                </Typography>
            </div>
            <div className="py-1">
                <div className="py">
                    <Typography>{message}</Typography>
                </div>
            </div>
        </div>
    );
};

export default ErrorScreen;
