export enum ETicketStatus {
    Invalid,
    Waiting,
    Holding,
    Calling,
    Processing,
    Reset,
    Finished,
    Dropped,
    RedirectedToWorkplace,
    RedirectedToOperation,
    RedirectedToOperator,
}