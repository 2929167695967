import React from 'react';
import './InputSearch.css';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IInputSearchProps {
    placeholder: string;
    hasIcon?: boolean;
    onChange(value: string): void;
}
const InputSearch: React.FC<IInputSearchProps> = ({
    onChange,
    placeholder,
    hasIcon,
}) => {
    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: { value },
        } = e;
        onChange(value);
    };
    return (
        <div
            className={`input-search-container ${
                hasIcon ? 'has-search-icon' : ''
            }`}>
            {hasIcon && (
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
            )}
            <input
                className="input-search"
                placeholder={placeholder}
                onInput={handleInput}
                type="text"
            />
        </div>
    );
};

export default InputSearch;
