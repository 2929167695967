import * as  React from 'react';
import {useHistory, useLocation, useParams} from "react-router";
import {usePreregVisitDate} from "../hooks/usePreregVisitDate";
import Calendar from '../components/Calendar/Calendar';
import {useState} from "react";
import './PreregDate.screen.css';
import Button from '../components/core/Button/Button';
import Typography from "../components/core/Typography/Typography";


const PreregDateScreen: React.FC<any> = ({onSelect}) => {
    const {pathname} = useLocation();
    const [date, setDate] = useState<Date | null>(null);
    const {oid: operationId, did: clientId} = useParams<any>();
    const {dates} = usePreregVisitDate({operationId, clientId});
    const history = useHistory();
    return (
            <div>
                <div className="py-2">
                    <Typography variant="h5" weight="bold">
                        Выберите дату
                    </Typography>
                </div>
                <div className="flex justify-content-center">
                    <Calendar
                        className="calendar"
                        tileDisabled={({ date}) => !dates?.includes(new Intl.DateTimeFormat('ru-RU').format(date))}
                        onChange={setDate} />
                </div>

                {date && (
                    <div className=" py-3">
                        <Button size="lg" onClick={() => history.push(`${pathname}/${new Intl.DateTimeFormat('ru-RU').format(date)}`)}>
                            <Typography>Дальше</Typography>
                        </Button>
                    </div>
                )}
            </div>
    )
}

export default PreregDateScreen;