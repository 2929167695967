import useSWR from 'swr';
import { API } from '../api/API';
import { Services } from '../api/services';
import { IMenuResponseData } from './../common/IMenuResponseData';
import { IMenu } from './../common/menu/IMenu';

export function useMenuList() {
    const { data, error } = useSWR<IMenuResponseData<IMenu[]>>(
        API.menu.read,
        Services.menu.getList,
    );

    return {
        parent: data && data.parent,
        menus: data && data.data,
        isLoading: !error && !data,
        isError: error,
    };
}
