import { IMenuResponseData } from '../../common/IMenuResponseData';
import { IMenu } from '../../common/menu/IMenu';
import { fetchData } from '../../utils/Http';
import { API } from '../API';

export interface IMenuService {
    getList(): Promise<any>;
}

export class MenuService implements IMenuService {
    public async getList(): Promise<IMenuResponseData<IMenu[]>> {
        const response = await fetchData<IMenuResponseData<IMenu[]>>({
            url: API.menu.read,
        });
        return response;
    }
}
