import React from 'react';
import 'react-spring-bottom-sheet/dist/style.css';
import './App.css';
import {useTheme} from './providers/ThemeProvider';
import Routes from './routes';

function App() {
    const { theme } = useTheme();

    return (
        <div className={`App ${theme}-theme`}>
            <Routes />
        </div>
    );
}

export default App;
