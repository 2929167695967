import React, { useMemo, useState } from 'react';
import InputSearch from '../components/core/InputSearch/InputSearch';
import Typography from '../components/core/Typography/Typography';
import { useOperationList } from '../hooks/useOperationList';
import { debounce } from '../utils/debounce';
import FlatList from '../components/core/FlatList/FlatList';
import { IOperation } from '../common/operation/IOperation';
import { useParams } from 'react-router';
import Nl2br from '../components/nl2br/Nl2br';

interface IOperationScreenProps {
    onItemClick?(item: IOperation): void;
}

const OperationScreen: React.FC<IOperationScreenProps> = ({ onItemClick }) => {
    const { did: departmentId } = useParams<any>();
    const [search, setSearch] = useState('');
    const { operations = [], isLoading } = useOperationList({ departmentId });

    const filteredOperations = useMemo(
        () =>
            !search
                ? operations
                : operations?.filter(operation =>
                      operation.title
                          .toLowerCase()
                          .includes(search.toLowerCase()),
                  ),
        [search, operations],
    );

    const onOperation = (operation: IOperation) => {
        onItemClick && onItemClick(operation);
    };
    return (
        <div>
            <div className="pt-2">
                <Typography variant="h5" weight="bold">
                    Выберите операцию
                </Typography>
            </div>

            <div className="py">
                <InputSearch
                    placeholder="Поиск"
                    hasIcon={true}
                    onChange={debounce(setSearch, 300)}
                />
            </div>

            <div className="operation-list">
                <FlatList
                    data={filteredOperations || []}
                    renderItem={(item: IOperation) => (
                        <div
                            className="py-1"
                            key={item.id}
                            onClick={() => onOperation(item)}>
                            <Nl2br str={item.title} replaceTo=" " />
                        </div>
                    )}
                    loading={isLoading}
                    divided
                />
            </div>
        </div>
    );
};

export default OperationScreen;
