export const API = {
    app: {
        init: '/sqs/init'
    },
    menu: {
        read: '/sqs/menu_list'
    },
    operation: {
        read: '/sqs/operation_list',
        readTop: '/sqs/top_operation_list',
        getIcon: '/sqs/get_icon'
    },
    ticket: {
        status: '/sqs/ticket_status',
        register: '/sqs/register_ticket',
        cancel: '/sqs/cancel_ticket'
    },
    department: {
        read: '/sqs/client_list'
    },
    preregistration: {
        operations: '/pre_registration/operation_list',
        departments: '/pre_registration/client_list',
        cancel: '/pre_registration/cancel',
        visit_date: '/pre_registration/visit_date',
        visit_time: '/pre_registration/visit_time',
        create: '/pre_registration/new'
    },
    fcm: {
        config: '/fcm-config.json'
    },
}