import { OperationService, IOperationService } from './Operation.service';
import { TicketService, ITicketService } from './Ticket.service';
import { MenuService, IMenuService } from './Menu.service';
import { AppService, IAppService } from './App.service';
import {IPreregService, PreregService} from "./Preregistration.service";

export const Services = {
    app: new AppService(),
    menu: new MenuService(),
    ticket: new TicketService(),
    opearation: new OperationService(),
    prereg: new PreregService()
}

export type IApi = {
    app: IAppService;
    menu: IMenuService;
    ticket: ITicketService;
    operation: IOperationService;
    prereg: IPreregService
}