import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Services } from '../api/services';
import { IResponseData } from '../common/IResponseData';
import { IMenu } from '../common/menu/IMenu';
import { IOperation } from '../common/operation/IOperation';
import { API } from './../api/API';

import firebase from 'firebase/app';
import 'firebase/messaging';
import { useHistory, useParams } from 'react-router';
import { useApp } from '../providers/AppProvider';

function useAppReady() {
    const [ready, setReady] = useState(false);
    const { id } = useParams<any>();
    const { setAppDepartment } = useApp();
    const history = useHistory();

    // прогреваем кеш
    const { data: menus } = useSWR<IResponseData<IMenu[]>>(
        ready ? API.menu.read : null,
        Services.menu.getList,
    );
    // const { data: topOperations } = useSWR<IResponseData<IOperation[]>>(
    //     ready ? API.operation.readTop : null,
    //     Services.opearation.getTop,
    // );
    const { data: operations } = useSWR<IResponseData<IOperation[]>>(
        ready ? API.operation.read : null,
        Services.opearation.getList,
    );

    const init = async () => {
        if (!id || id === 'undefined') {
            return history.push('/department/');
        }
        let token = '';
        try {
            const config = await Services.app.getFcmConfig();
            if (!firebase.apps.length) {
                firebase.initializeApp(config);
            } else {
                firebase.app();
            }
            const messaging = firebase.messaging();
            token = await messaging.getToken({ vapidKey: config.vapidKey });
        } catch (error) {
            console.log('failed initialize firebase', error);
        }
        setAppDepartment(id);
        await Services.app.init(id, token);
        setReady(true);
    };

    useEffect(() => {
        try {
            init();
        } catch (error) {
            console.log('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { ready: ready && !!(menus && operations) };
}

export default useAppReady;
