import useSWR from 'swr';
import {API} from '../api/API';
import {IResponseData} from '../common/IResponseData';
import {IOperation} from '../common/operation/IOperation';
import {fetchData} from "../utils/Http";

interface IUseOperationsProps {
    departmentId?: string;
}

const fetcher = (url: string, key?: string) => fetchData<IResponseData<IOperation[]>>( {url, params: {key}});

export function useOperationList({departmentId = '' }: IUseOperationsProps) {
    const { data, error } = useSWR<IResponseData<IOperation[]>>(
        departmentId ? [API.preregistration.operations, departmentId] : API.operation.read,
        fetcher
    );
    return {
        operations: data && data.data,
        isLoading: !error && !data,
        isError: error,
    };
}
