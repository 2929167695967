import { API } from '../../API';

export const ticketMock = {
    [API.ticket.status]: {
        data: {
            id: 120,
            status: null,
            number: '2202',
        },
    },
};
