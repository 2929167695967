import React from 'react';
import './InputCode.css'

interface IInputCodeProps {
    placeholder: string
    onChange(value: string): void
}


const InputCode: React.FC<IInputCodeProps> = ({onChange, placeholder}) => {

    const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
        const {currentTarget: {value}} = e;
        onChange(value);

        // change fontSize by value length
        const defaultFontSize = 10;
        const inputSymbolWidth = e.currentTarget.clientWidth / 13;
        const valueWidth = value.length * inputSymbolWidth;

        // input value size > input size
        if(valueWidth > e.currentTarget.clientWidth) {
            const fontSize = defaultFontSize - value.length * 0.13;
            e.currentTarget.style.fontSize = `${fontSize}vw`;
        }
        // default size
        else {
            e.currentTarget.style.fontSize = `${defaultFontSize}vw`;
        }
    }
    return (
        <input className="input-code" placeholder={placeholder} onInput={handleInput} type="text" />
    )
}

export default InputCode
