import React from 'react';
import {ITicketStatus} from '../common/ticket/ITicketStatus';
import Typography from '../components/core/Typography/Typography';

interface ITicketCancelScreenProps {
    data: ITicketStatus;
}

const TicketCancelScreen: React.FC<ITicketCancelScreenProps> = ({
                                                                    data,
                                                                }) => {
    return (
        <div className="px-2">
            <div>
                <Typography variant="h6" weight="bold">
                    Талон отменен
                </Typography>
            </div>
            <div className="py-1">
                <div className="py">
                    <Typography>Услуга: {data?.operation_title}</Typography>
                </div>
            </div>
        </div>
    );
};

export default TicketCancelScreen;
