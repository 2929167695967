import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ITicketStatus } from '../../common/ticket/ITicketStatus';
import SplashScreen from '../../components/SpashScreen/SplashScreen';
import useAppReady from '../../hooks/useAppReady';
import useModal from '../../hooks/useModal';
import { useTicket } from '../../hooks/useTicket';
import { useTicketStatus } from '../../hooks/useTicketStatus';
import AppLayout from '../../layouts/AppLayout';
import { useApp } from '../../providers/AppProvider';
import AppMenuScreen from '../../screens/AppMenu.screen';
import KioskScreen from '../../screens/Kiosk.screen';
import KioskErrorScreen from '../../screens/KioskError.screen';
import MenuScreen from '../../screens/Menu.screen';
import OperationScreen from '../../screens/Operation.screen';
import PreregCodeScreen from '../../screens/PreregCode.screen';
import ThxScreen from '../../screens/Thx.screen';
import TicketScreen from '../../screens/Ticket.screen';
import TicketCancelScreen from '../../screens/TicketCancel.screen';
import RouteSlideAnimation from '../RouteSlideAnimation';

const KioskRouterPrebuild = () => {
    const { ready } = useAppReady();
    return ready ? (
        <KioskRoutes />
    ) : (
        <div className="fade-anim">
            <SplashScreen />
        </div>
    );
};

const KioskRoutes = () => {
    const { appCls } = useApp();
    const { error, register, cancel } = useTicket();
    const { url } = useRouteMatch();
    const { data } = useTicketStatus();
    const [showThx, setShowThx] = useState(false);
    const [ticketWasCanceled, setTicketWasCanceled] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    const [prevStatus, setPrevStatus] = useState<ITicketStatus | null>(null);
    const history = useHistory();

    const {
        open: operationOpen,
        handleOpen: operationHandleOpen,
        handleClose: operationHandleClose,
    } = useModal();
    const {
        open: appMenuOpen,
        handleOpen: appMenuHandleOpen,
        handleClose: appMenuHandleClose,
    } = useModal();

    useEffect(() => {
        if (!data?.status) {
            setShowThx(false);
            setShowTicket(false);
            setShowThx(() => !!prevStatus);
            return;
        }
        setShowTicket(true);
        setPrevStatus(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.status]);

    useEffect(() => {
        if (error) {
            operationHandleClose();
            history.push(`/department/mdu/kiosk/error`, error);
        }
    }, [error]);

    const onThxClose = () => {
        // в FF, успевает кликнуть по менюшке и зарегать новый талон
        setTimeout(() => {
            setPrevStatus(null);
            setShowThx(false);
        }, 100);
        // дожидаемся закрытия попапа
        setTimeout(() => setTicketWasCanceled(() => false), 500);
    };

    const onCancel = () => {
        cancel();
        setTicketWasCanceled(true);
    };

    return (
        <RouteSlideAnimation>
            <AppLayout
                onMenu={appMenuHandleOpen}
                onSearch={operationHandleOpen}>
                <Switch>
                    <Route
                        path={`${url}/prereg/code`}
                        component={PreregCodeScreen}
                    />
                    <Route path={`${url}/menu/:id`} component={MenuScreen} />
                    <Route path={`${url}/error`} component={KioskErrorScreen} />
                    <Route path={`${url}`} component={KioskScreen} />
                </Switch>

                <BottomSheet
                    className={`${appCls}`}
                    open={appMenuOpen}
                    onDismiss={appMenuHandleClose}
                    snapPoints={() => 300}>
                    <AppMenuScreen onLinkClick={appMenuHandleClose} />
                </BottomSheet>

                <BottomSheet
                    className={`${appCls}`}
                    open={operationOpen}
                    onDismiss={operationHandleClose}
                    snapPoints={({ maxHeight }) => maxHeight - 100}>
                    <div className="px-2">
                        <OperationScreen onItemClick={register} />
                    </div>
                </BottomSheet>

                <BottomSheet
                    open={showTicket}
                    className={`${appCls}`}
                    snapPoints={() => 400}>
                    <TicketScreen data={data!} onCancel={onCancel} />
                </BottomSheet>

                <BottomSheet
                    className={`${appCls}`}
                    open={showThx}
                    onDismiss={onThxClose}
                    snapPoints={() => 200}>
                    {ticketWasCanceled ? (
                        <TicketCancelScreen data={prevStatus!} />
                    ) : (
                        <ThxScreen data={prevStatus!} />
                    )}
                </BottomSheet>
            </AppLayout>
        </RouteSlideAnimation>
    );
};

export default KioskRouterPrebuild;
