import * as React from 'react';
import { strReplace } from '../../utils/strReplace';

interface INl2brProps {
    str: string;
    pattern?: string;
    replaceTo?: string;
}
const Nl2br: React.FC<INl2brProps> = ({
    str,
    pattern = '{br}',
    replaceTo = '<br />',
}) => {
    if (!str) {
        return <>{str}</>;
    }

    const result = strReplace(str, pattern, replaceTo);
    return <span dangerouslySetInnerHTML={{ __html: result }} />;
};
export default Nl2br;
