import * as React from 'react';
import Button from '../core/Button/Button';
import Typography from "../core/Typography/Typography";
import './TimePicker.css'
import Divider from "../core/Divider/Divider";
import {IVisitTime} from "../../common/preregistration/IVisitTime";
interface ITimePickerProps {
    times: IVisitTime[];
    onItemClick(time: string): void
}
const transtalter = {
    morning: 'Утро',
    day: "День",
    evening: 'Вечер'

}
const TimePicker: React.FC <ITimePickerProps> = ({times = [], onItemClick}) => {
    const groupedTimes = times.reduce( (acc: any, next) => {
        const time = next.from.split(':');
        const hours = parseInt(time[0], 10);

        if(hours >= 17) {
            acc.evening.push(next);
        }
        if(hours > 11 && hours < 17) {
            acc.day.push(next);
        }
        else if(hours <= 11) {
            acc.morning.push(next);
        }

        return acc;
    }, {morning: [], day: [], evening: []});

    return (
        <div className="timepickers">
            {Object.entries(groupedTimes).map(([key, value = []]) => {
                return (value as IVisitTime[]).length ? (
                    <div key={key}>
                        <div >
                            <Typography variant="h6">{transtalter[key as keyof typeof transtalter]}</Typography>
                        </div>
                        <div className="timepicker">
                        {(value as IVisitTime[]).map((time, index) => (
                            <div className="timepicker__item" key={time.from + index}>
                                <Button variant="outlined-active-color" cls={time.peak ? 'peak' : ''} onClick={() => onItemClick(time.from)}>
                                    <Typography>{time.from}</Typography>
                                </Button>
                            </div>
                        ))}
                        </div>
                        <Divider />
                    </div>
                ) : null
            })}
            {/*{times.map((time, index) => (*/}
            {/*    <div className="timepicker__item" key={time + index}>*/}
            {/*        <Button variant="outlined-active-color" onClick={() => onItemClick(time)}>*/}
            {/*            <Typography>{time}</Typography>*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*))}*/}
        </div>
    )
}

export default TimePicker;
