import React from 'react';
import './Button.css';

interface IButtonProps {
    size?: size;
    variant?: variant;
    disabled?: boolean;
    cls?: string,
    onClick(): void;
}
type variant = 'primary' | 'secondary' | 'outlined-active-color';
type size = 'xs' | 'md' | 'lg';
const Button: React.FC<IButtonProps> = ({disabled = false, size = 'md', variant = 'primary', cls = '', children, onClick}) => {
    const clss = ['button', `button_${size}`, `button_${variant}`, cls];
    return (
        <button disabled={disabled} onClick={onClick} className={clss.join(' ')}>{children}</button>
    )
}


export default Button
