import { useEffect } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { EErrorCode } from '../common/EErrorCode';
import { IMenu } from '../common/menu/IMenu';
import MenuListDefault from '../components/MenuList/MenuListDefault';
import { useMenuList } from '../hooks/useMenuList';
import useModal from '../hooks/useModal';
import { useTicket } from '../hooks/useTicket';
import { useApp } from '../providers/AppProvider';
import ErrorScreen from './Error.screen';
function findByIds(ids: string[], targetArray: any): any {
    let target = null;
    const id = ids.shift();

    // eslint-disable-next-line eqeqeq
    target = targetArray.find((menu: any) => menu.id == id);

    if (target && target.children && ids.length) {
        return findByIds(ids, target.children);
    }
    return target;
}

const MenuScreen = () => {
    const { appCls } = useApp();
    const { url, path } = useRouteMatch();
    const { menus = [] } = useMenuList();
    const { error, register } = useTicket();
    const location = useLocation();
    const history = useHistory();
    const [menuUrl, menuIdsString] = url.split('/menu/');
    const menuIds = menuIdsString.split('/');
    const targetMenu = findByIds(menuIds.slice(), menus);

    const {
        open: errorOpen,
        handleOpen: errorHandleOpen,
        handleClose: errorHandleClose,
    } = useModal();

    useEffect(() => {
        if (error) {
            const { message, error: errorCode } = error;
            history.push(`/department/mdu/kiosk/error`, {
                message,
                error: errorCode || EErrorCode.FAILURE,
            });
            // errorHandleOpen();
        }
    }, [error]);

    const onDir = (dir: IMenu) => {
        history.push(`${url}/${dir.id}`);
    };

    return (
        <Switch location={location}>
            <Route exact path={`${path}`}>
                {targetMenu ? (
                    <>
                        <MenuListDefault
                            onOperation={register}
                            onDir={onDir}
                            parent={targetMenu}
                            menus={targetMenu.children}
                        />
                        <BottomSheet
                            className={`${appCls}`}
                            open={errorOpen}
                            onDismiss={errorHandleClose}
                            snapPoints={() => 300}>
                            {error && <ErrorScreen message={error.message} />}
                        </BottomSheet>
                    </>
                ) : (
                    <Redirect to={menuUrl} />
                )}
            </Route>
            <Route path={`${url}/:id`}>
                <MenuScreen />
            </Route>
        </Switch>
    );
};

export default MenuScreen;
