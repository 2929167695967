import { EErrorCode } from './../common/EErrorCode';
import { useState } from 'react';
import { Services } from '../api/services';
import { API } from './../api/API';
import { mutate } from 'swr';
import { IMenuOperation } from '../common/operation/IMenuOperation';
import { useTicketStatus } from './useTicketStatus';
export function useTicket() {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);
    const { data: ticket } = useTicketStatus();

    async function register(operation: IMenuOperation) {
        try {
            if (loading || ticket?.status !== null) {
                return;
            }
            setLoading(true);
            const response = await Services.ticket.register(operation.id);
            if (!response.success) {
                throw response;
            }
            await mutate(API.ticket.status);
            setData(response.data);
        } catch (error: any) {
            let errorMessage =
                '<h3>Сейчас мы не можем выдать вам талон.</h3><div class="error-description">Пожалуйста, обратитесь к администратору зала для решения этого вопроса.<div>';

            switch (error.error) {
                case EErrorCode.WORK_TIME: {
                    errorMessage =
                        '<h3>Пожалуйста, обратитесь в рабочие часы.</h3>';
                    break;
                }
            }

            setError({
                ...error,
                message: errorMessage,
            });
        } finally {
            setLoading(false);
        }
    }

    async function prereg(access_code: number) {
        try {
            if (loading || ticket?.status !== null) {
                return;
            }
            setLoading(true);
            const response = await Services.ticket.prereg(access_code);
            await mutate(API.ticket.status);
            setData(response.data);
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    async function cancel() {
        try {
            if (loading || ticket?.status === null) {
                return;
            }
            setLoading(true);
            const response = await Services.ticket.cancel();
            await mutate(API.ticket.status);
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    return {
        data,
        error,
        register,
        loading,
        prereg,
        cancel,
    };
}
