import * as  React from 'react';
import {useState} from 'react';
import TextField from "../components/core/TextField/TextField";
import Typography from "../components/core/Typography/Typography";
import Button from "../components/core/Button/Button";
import useModal from "../hooks/useModal";
import {BottomSheet} from "react-spring-bottom-sheet";
import IconButton from '../components/core/IconButton/IconButton';
import {faCopy} from '@fortawesome/free-solid-svg-icons'
import {useHistory, useParams} from "react-router";
import copy from "copy-to-clipboard";
import {toast} from "react-toastify";
import {Services} from "../api/services";
import {useFormik} from "formik";
import * as Yup from 'yup';

interface IPreregPersonalScreenProps {
}

interface IRouteParams {
    did: string;
    oid: string;
    time: string;
    date: string
}

const initialValues = {
    name: '',
    fatherName: '',
    lastName: '',
    phone: '',
    email: ''
};
const validationSchema = Yup.object({
    name: Yup.string()
        .max(30, 'Не должно превышать 30 символов')
        .required('Обязательно для заполнения'),
    lastName: Yup.string()
        .max(30, 'Не должно превышать 30 символов')
        .required('Обязательно для заполнения'),
    fatherName: Yup.string()
        .max(30, 'Не должно превышать 30 символов'),
    email: Yup.string().email('Некорректный адрес').required('Обязательно для заполнения'),
    phone: Yup.string()
        .min(10, 'Некорректный телефон')
        .max(13, 'Некорректный телефон')
        .required('Обязательно для заполнения'),
});

const PreregPersonalScreen: React.FC<IPreregPersonalScreenProps> = () => {
    const {open, handleOpen} = useModal();
    const {open: errorOpen, handleOpen: errorHandleOpen, handleClose: errorHandleClose} = useModal();
    const [preregCode, setPreregCode] = useState('');
    const history = useHistory();
    const {did: key, oid: operation_id, time: visit_time, date: visit_date} = useParams<IRouteParams>();

    const {values, handleChange, touched, errors, handleBlur, handleSubmit} = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            const {name, fatherName, lastName, phone, email} = values;
            try {
                const {success, data} = await Services.prereg.create({
                    title: `${name} ${fatherName} ${lastName}`,
                    operation_id: parseInt(operation_id, 10),
                    description: '',
                    key,
                    visit_date,
                    visit_time,
                    phone,
                    email,
                });
                if (!success) {
                    throw new Error('wrong');
                }
                handleOpen();
                setPreregCode(data.access_code);
            } catch (error) {
                // errror
                errorHandleOpen();
            }

        }
    });

    const onCopy = () => {
        copy(preregCode);
        toast("Код регистрации скопирован!", {autoClose: 2000});
    }

    const {name, fatherName, lastName, email, phone} = values;
    return (
        <div>
            <div className="pt-2">
                <Typography variant="h5" weight="bold">
                    Персональные данные
                </Typography>
            </div>
            <div className="py">
                <TextField
                    value={lastName}
                    label="Фамилия"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastName && errors.lastName}
                />
            </div>
            <div className="py">
                <TextField
                    value={name}
                    label="Имя"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                />
            </div>
            <div className="py">
                <TextField
                    value={fatherName}
                    label="Отчество"
                    name="fatherName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.fatherName && errors.fatherName}
                />
            </div>
            <div className="py">
                <TextField
                    value={phone}
                    label="Телефон"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && errors.phone}
                />
            </div>
            <div className="py">
                <TextField
                    value={email}
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                />
            </div>

            <div className="py-3 px-3">
                <Button size="lg" onClick={handleSubmit}>
                    <Typography variant="subtitle" weight="bold">Записаться</Typography>
                </Button>
                <div className="text-center py-1" style={{opacity: 0.5}}>
                    <Typography className="py-1" variant="small">
                        Нажимая "Записаться" вы подтверждаете свое согласие на
                        обработку персональных данных
                    </Typography>
                </div>
            </div>


            <BottomSheet
                open={errorOpen}
                snapPoints={() => 150}
                onDismiss={errorHandleClose}
            >
                <div className="px-2">
                    <div className="text-center">
                        <Typography variant="h3" weight="bold">
                            Ошибка
                        </Typography>
                        <div className="py-1">
                            <Typography variant="h5" weight="semibold">
                                Что-то пошло не так
                            </Typography>
                        </div>
                    </div>
                </div>

            </BottomSheet>

            <BottomSheet
                open={open}
                snapPoints={() => 270}
            >
                <div className="px-2">
                    <div>
                        <Typography variant="subtitle" weight="semibold">
                            Ваш код регистрации
                        </Typography>
                    </div>
                    <div className="text-center flex justify-content-center align-items-center py-3">

                        <Typography variant="h4" weight="bold">
                            {preregCode}
                        </Typography>
                        <IconButton size="lgx" icon={faCopy} onClick={onCopy}/>
                    </div>
                </div>
                <div className="px-3 pt-3">
                    <Button size="lg" onClick={() => history.push('/')}>
                        <Typography variant="subtitle" weight="bold">
                            На главную
                        </Typography>
                    </Button>
                </div>
            </BottomSheet>
        </div>
    )
}

export default PreregPersonalScreen;
