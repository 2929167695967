import * as  React from 'react';
import Divider from "../Divider/Divider";
import './FlatList.css';
import Spinner from "../Spinner/Spinner";
import {CSSTransition} from "react-transition-group";

interface IFlatListProps {
    data: any[];
    renderItem(item: any): any;
    divided?: boolean;
    loading?: boolean;
}

const FlatList: React.FC<IFlatListProps> = ({data, renderItem, divided, loading}) => {
    return (
        <div className={`flat-list ${loading && 'flat-list_loading'}`}>
            <CSSTransition unmountOnExit in={loading} timeout={500} classNames="fade">
                <Spinner className="flat-list__loader" />
            </CSSTransition>
            {
                data.map((item: any) => {
                    return (
                        <div key={`flat-item-${item.id}`} className="flat-list__item">
                            {renderItem(item)}
                            {divided && <Divider/>}
                        </div>
                    )
                })
            }
        </div>
    );
}

export default FlatList