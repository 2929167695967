import useSWR from 'swr';
import {API} from '../api/API';
import {Services} from '../api/services';

export function useTicketStatus() {
    const {data, error, revalidate} = useSWR(
        API.ticket.status,
        Services.ticket.status,
        {
            refreshInterval: 1000,
            onError: (error) => {
                if (error?.response?.status === 403) {
                    window.location.reload();
                }
            }
        },
    );
    return {
        data: data && data.data,
        isLoading: !error && !data,
        isError: error,
        revalidate,
    };
}
